import './util/docReady';

window.docReady(letsRoll);

function letsRoll() {
    function readMoreToggle() {
        let buttonElement = document.querySelector('.js-read-more-button');
        if(!buttonElement) return;

        let readMoreContainer =  document.querySelector('.js-read-more');

        buttonElement.addEventListener('click', () => {
            console.log('click');
            buttonElement.classList.add('is-active');
            readMoreContainer.classList.add('is-active');
        });
    }

    readMoreToggle();
}
